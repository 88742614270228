import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import ReactFullpage from '@fullpage/react-fullpage';

import ImageTextSideBySide
  from '../SectionBlocks/RepeatableSections/ImageTextSideBySide';
import Paragraph from '../Atoms/Paragraph';
import CustomLink from '../Atoms/CustomLink';
import Button from '../Atoms/Button';
import Footer from '../Footer';
import Heading from '../Atoms/Heading';

// ********************************* UI *********************************
export class RestaurantsSectionListUI extends Component {
  render() {
    const { data, cms = false } = this.props;
    const { edges } = data.allMarkdownRemark;

    const sections = edges.map(e => ({
      bgImg: e.node.frontmatter.introImg,
      coverIcon: e.node.frontmatter.coverIcon,
      title: e.node.frontmatter.name,
      desc: e.node.frontmatter.intro,
      link: e.node.frontmatter.link,
    }));

    return (
      <ReactFullpage
        licenseKey="B22064FC-896D48BC-A3073618-5D5858E3"
        scrollingSpeed={600}
        responsiveWidth={992}
        responsiveHeight={900}
        recordHistory={false}
        render={({ state, fullpageApi }) => (
          <ReactFullpage.Wrapper>
            {sections.map((section, i) => (
              <ImageTextSideBySide
                key={section.title}
                img={section.bgImg}
                iconOnImage={section.coverIcon}
                imageOnRight={i % 2 !== 0}
                textAlign={['center', 'center', 'center', 'left']}
                noEffect={i === 0}
              >
                <Heading h={1} pb={[4, 4, 5]} width="100%" display={['none', 'none', 'none', 'block']}>
                  {section.title}
                </Heading>
                <Paragraph pb={5}>{section.desc}</Paragraph>
                <CustomLink to={`/${section.link}`}>
                  <Button width={160} text="viewMore" />
                </CustomLink>
              </ImageTextSideBySide>
            ))}
            <Footer pathname="/events" />
          </ReactFullpage.Wrapper>
        )}
      />
    );
  }
}

// ********************************* Render page *********************************

const RestaurantsSectionList = () => (
  <StaticQuery
    query={graphql`  
      query RestaurantsAllPage {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }
          filter: {
            frontmatter: {
              templateKey: { eq: "restaurants-individual-page" }
            }
          }
        ) {
          totalCount
          edges {
            node {
              frontmatter {
                name
                intro
                link
                introImg {
                  alt
                  image {
                    childImageSharp {
                      fluid(maxWidth: 2048, quality: 90) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                coverIcon {
                  alt
                  image {
                    childImageSharp {
                      fixed(width: 1500, quality: 90) {
                        ...GatsbyImageSharpFixed_tracedSVG
                      }
                    }
                    extension
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <RestaurantsSectionListUI data={data} />}
  />
);

export default RestaurantsSectionList;
