import React, { Component } from 'react';
import { graphql } from 'gatsby';

import { PageHelmet } from '../components/PageHelmet';
import RestaurantsSectionList from '../components/Restaurants/RestaurantsSectionList';

// ********************************* UI Template (Shared with CMS) *********************************
export class RestaurantAllPageTemplate extends Component {
  render() {
    const {
      title, description, cms = false,
    } = this.props;
    return (
      <>
        <PageHelmet title={title} description={description} />
        <RestaurantsSectionList />
      </>
    );
  }
}

// ********************************* Render page *********************************
const RestaurantAllPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <RestaurantAllPageTemplate {...frontmatter} />;
};

export default RestaurantAllPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query RestaurantsAllPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        sections {
          bgImg {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          title
          desc
          link
        }
      }
    }
  }
`;
